// Base, site-wide styles 

@import "variables";
@import "base";
@import "typography";

// Component styles 

@import "header";
@import "footer";
@import "awards";
// Page styles  

@import "home";