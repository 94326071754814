.footer {
    &__link {
        color: #000 !important;
        float: right;
        margin-left: 20px;
        text-decoration: none !important;
        transition: all 0.3s ease-in-out;
        &:hover {
            color: $color--accent !important;
        }
    }
    .wrapper {
        padding-left: 0;
    }
}