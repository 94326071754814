.home {
    &__avatar {
        background-image: url('/img/shaleen-title-headshot.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        border-radius: 50%;
        height: 250px;
        margin-bottom: 3em;
        margin-right: 3em;
        width: 250px;
        @media all and (max-width: 749px){
            margin: 0 auto 3em auto;
        }  
    }
    &__intro {
        display: flex;
        margin-bottom: 2em;
        flex-wrap: wrap;
        justify-content: center;
        p {
            background: $color--border;
            box-shadow: 3px 3px #d1d1d1;
            font-size: 16px;
            font-style: italic;
            padding: 1.25em;
            min-width: 300px;
            width: 50%;
        }
    }
}