.award {
    &--highlighted {
        position: relative;
        &:before {
            content: url('/img/icons/iconmonstr-trophy-6.svg');
            font-size: 1em;
            position: absolute;
            left: -2em;
            @media (max-width: 900px) {
                content: none;
            }
        }
    }
}