// Base styles go here

body {
    background: $color--background;
}

a {
    color: $color--accent;
    &:hover {
        color: $color--accent;
    }
    &:visited {
        color: $color--accent;
    }
}

li {
    margin-bottom: 1em;
}

ul {
    font-size: 18px;
    line-height: 1.75em;
    list-style: none;
    margin: 0;
}


.wrapper {
    padding-left: 2em;
    padding-right: 2em;
}